import React from 'react';
import { WallPlannerDatesRow } from "./components/WallPlannerDatesRow";
import { WallPlannerTeamPlan } from "./components/WallPlannerTeamPlan";
import { useSelector } from "react-redux";
import { WithWorkPlannerUserActivities } from "../../../hooks/WithWorkPlannerUserActivities";
import { WithWorkPlannerTeamEvents } from "../../../hooks/WithWorkPlannerTeamEvents";
import DateFlicker, { DateScale } from "../../UI/molecules/DateFlicker";
import {
  WorkPlannerContainer,
  WorkPlannerControls,
  WorkPlannerHeader,
} from '../../UI/molecules/work-planner/WorkPlannerStructure';
import { TeamMultiSelectWithFavourites } from "../../UI/organisms/team-drop-down-list/TeamMultiSelectWithFavourites";
import { TeamEntity } from "../../../models/team.models";
import { Tag } from "../../../models/tags.models";
import { selectFollowingTeams, selectFollowingWorkGroups } from "../../../store/ducks/followingTeamsAndTags.duck";
import { WorkPlannerLayout } from "../../UI/molecules/work-planner/WorkPlannerLayout";
import { WithWeeksInCurrentCalendarMonth } from "../../../hooks/WithWeekInCurrentCalendarMonth";
import { selectAllTeams } from "../../../store/ducks/config.duck";
import { selectAllTags } from "../../../store/ducks/userTags.duck";
import { WallPlannerWorkGroupPlan } from "./components/WallPlannerWorkGroupPlan";
import { WallPlannerHoverBox } from "./components/WallPlannerHoverBox";
import { selectIsBoxVisible } from "./data/WallPlannerHoverBox.duck";
import { PageTitle } from "../../UI/atoms/PageTitle";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import { BiggerThanTabletOnly, TabletOnly } from "../../UI/atoms/DeviceSpecific";
import { selectCalendarDate } from "../../../store/ducks/dashboard.duck";

export function WorkPlannerPage() {
  const allTeams = useSelector(selectAllTeams);
  const workGroups = useSelector(selectAllTags);
  const followedTeams = useSelector(selectFollowingTeams);
  const followedGroups = useSelector(selectFollowingWorkGroups);
  const isHoverBoxVisible = useSelector(selectIsBoxVisible);

  const weeksInMonth = WithWeeksInCurrentCalendarMonth();
  WithWorkPlannerUserActivities();
  WithWorkPlannerTeamEvents();
  const calendarDate = useSelector(selectCalendarDate);

  const onGroupsSelected = (team: TeamEntity[], workGroups: Tag[]) => {

  }

  return (
    <WorkPlannerLayout numberOfWeeksInMonth={weeksInMonth.length+1}>
      <TabletOnly style={{padding: '0 16px', marginTop: 8}}>
        <PageTitle includeMobileGutters={true} text={'work-planner.work-planner'} />
        <DateFlicker dateScale={DateScale.Month} updateGlobalDate={true} date={calendarDate} />
      </TabletOnly>
      <WorkPlannerHeader>
        <BiggerThanTabletOnly style={{minWidth: 300}}>
          <PageTitle text={'work-planner.work-planner'} />
        </BiggerThanTabletOnly>
        <WorkPlannerControls>
          <TeamMultiSelectWithFavourites onSelect={onGroupsSelected}
                                         teams={allTeams}
                                         workGroups={workGroups}
                                         outerClassName={'multiSelect__wrapper'}
                                         multiSelectClassName={'multiSelect__dropDown'}
                                         reloadTeamWhereaboutsOnSelect={true} />
          <OutlineButton link="/whereabouts/work-planner/event"
                         text="work-planner.add-event-project"
                         fullWidth={false}
                         style={{maxWidth: 220, marginBottom: 0}}
                         size="medium"/>
        </WorkPlannerControls>
      </WorkPlannerHeader>
      <WorkPlannerContainer id={'workPlannerContainer'}>
        <WallPlannerDatesRow weeksInMonth={weeksInMonth} />
        {(followedTeams || []).map((team, key) => (
          <WallPlannerTeamPlan key={key} team={team}
                               weeksInMonth={weeksInMonth}
                               isFirst={key === 0}/>
        ))}
        {(followedGroups || []).map((tag, key) => (
          <WallPlannerWorkGroupPlan key={key} workGroup={tag}
                                    weeksInMonth={weeksInMonth}
                                    isFirst={key === 0}/>
        ))}

      </WorkPlannerContainer>
      {isHoverBoxVisible && <WallPlannerHoverBox/>}
    </WorkPlannerLayout>
  )
}
